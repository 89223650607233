import {Box, Container, Paper} from "@mui/material";
import * as React from 'react';
import Typography from "@mui/material/Typography";

export default function NoSelectedTripListPage() {
    return (
        <Container maxWidth={"lg"}>
            <Box display="flex"
                 justifyContent="center" alignItems="center">
                <Paper sx={{width:'100%'}}>
                    <Typography
                        variant="h6"
                        component="a"
                        sx={{
                            mr: 2,
                            // display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                            No trip has been selected.
                            Select a trip to view it's items.
                    </Typography>
                </Paper>
            </Box>
        </Container>


    );
}