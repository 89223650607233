
function Profile(user) {
    return (
        <div>
            <div>
                Profile
            </div>
        </div>
    );
}

export default Profile;