import {Paper} from "@mui/material";
import * as React from 'react';
import { MuiMarkdown } from 'mui-markdown';

export default function IdeasPage() {
    return (
        <Paper sx={{justifyContent: 'flex-start', textAlign: 'left'}}>
            <MuiMarkdown>
                #### Ideas For Use
            </MuiMarkdown>
            <ul>
                <li>Grocery List</li>
                <li>A shared list of who is bringing what to an event</li>
                <li>A way to share the status of a list of items to do</li>
            </ul>
        </Paper>

    );
}