import {initializeApp} from "firebase/app";
import {collection, getFirestore, onSnapshot, query, where, addDoc, getDocs } from "firebase/firestore";
import { GoogleAuthProvider, getAuth, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, signOut} from "firebase/auth";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
    apiKey: "AIzaSyClVPFF8_-h6fvmtgwphVrG83lDS1z0vFM",
    authDomain: "excursion-1ad2f.firebaseapp.com",
    projectId: "excursion-1ad2f",
    storageBucket: "excursion-1ad2f.appspot.com",
    messagingSenderId: "462217483781",
    appId: "1:462217483781:web:1e817d1799642e8b23ee6c",
    measurementId: "G-LCZJJQQHM2"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)
export const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6Lfr2KMmAAAAAHK069uijMG6zB2F5lJkdIq78rq3'),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
});

export const streamGearListItems = (id, snapshot, error) => {
    const q = query(collection(db, "Gear"), where("trip", "==", id));
    return onSnapshot(q, snapshot, error);
};

export const streamTripListItemsForUser = (id, snapshot, error) => {
    const q = query(collection(db, "Trip"), where('users', 'array-contains', id));
    return onSnapshot(q, snapshot, error);
};

export const getUsers = (snapshot, error) => {
    const q = query(collection(db, "users"));
    return onSnapshot(q, snapshot, error);
};

// export const setDBAppState = async (state) => {
//     await setDoc(doc(db, "AppState", "singleton"), state);
// }
//
// export const streamAppState = (snapshot, error) => {
//     return onSnapshot(doc(db, "AppState", "singleton"), (doc) => {
//         console.log("Current data: ", doc.data());
//     });
// }

//-----------------------Login Calls---------------------------------

export const signInWithGoogle = async () => {
    try {
        const res = await signInWithPopup(auth, googleProvider);
        const user = res.user;
        const q = query(collection(db, "users"), where("uid", "==", user.uid));
        const docs = await getDocs(q);
        if (docs.docs.length === 0) {
            await addDoc(collection(db, "users"), {
                uid: user.uid,
                name: user.displayName,
                authProvider: "google",
                email: user.email,
            });
        }
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

export const logInWithEmailAndPassword = async (email, password) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
        console.error(err);
        if(err.message === "Firebase: Error (auth/wrong-password)."){
            alert("Unable to find that Username and Password combination.  Please try again");
        }
    }
};

export const registerWithEmailAndPassword = async (name, email, password) => {
    try {
        const res = await createUserWithEmailAndPassword(auth, email, password);
        const user = res.user;
        await addDoc(collection(db, "users"), {
            uid: user.uid,
            name,
            authProvider: "local",
            email,
        });
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

export const sendPasswordReset = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        alert("Password reset link sent!");
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

export const logout = () => {
    signOut(auth);
};