import {addDoc, doc, deleteDoc, collection, getFirestore} from "firebase/firestore";
import React, {useEffect, useState} from 'react';
import * as FirestoreService from './Services/firestore';
import AlignItemsList from "./ListComponent/list";
import {Box, Button, Container, IconButton, Modal, Paper, Stack, TextField} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Typography from "@mui/material/Typography";

export default function GearList(props) {
    const db = getFirestore()
    const [gearListData, setGearListData] = useState([]);
    const [setError] = useState();
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [quantity, setQuantity] = useState('');
    const [checked, setCheckedState] = useState([])

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        if( typeof props.tripId == 'string')
        {
            const unsubscribe1 = FirestoreService.streamGearListItems( props.tripId,
                (querySnapshot) => {
                    const updatedGearListData =
                        querySnapshot.docs.map((doc) =>
                            (
                                {
                                    id:doc.id,
                                    name: doc.data().name,
                                    items:{
                                        description: doc.data().description,
                                        quantity: doc.data().quantity
                                    }
                                }
                            )
                        );
                    console.log(updatedGearListData)
                    // if(gearList != updatedGearList){
                    setGearListData(updatedGearListData);
                    // }
                },
                (error => setError('gear-list-item-get-fail'))
            );
            return () => {unsubscribe1();}
        }
    }, [setError, props]); //Empty array for dependencies forces single call

    // Callback function to handle data received from the
    //child component
    function handleCallback (childData) {
        // Update the name in the component's state
        setCheckedState(childData);
    }

    async function handleDelete  () {
        // Update the name in the component's state
        console.log(gearListData);
        gearListData.forEach(async (item, index, object) =>{
            if (checked.includes(item.id)) {
                await deleteDoc(doc(db, "Gear", item.id));
            }

        });
    }



    async function handleSubmit(e) {
        e.preventDefault();
        console.log(e.target[0].value)

        const docRef = await addDoc(collection(db, "Gear"), {
            name: e.target[0].value,
            description: e.target[2].value,
            quantity: e.target[4].value,
            trip: props.tripId
        });
        console.log("Document written with ID: ", docRef.id);

        setOpen(false)

    }

    return (
        <Container maxWidth={"lg"}>
            <Box display="flex"
                 justifyContent="center" alignItems="center">
                <Paper sx={{background:"#FFCB2B", width:'100%'}}>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        sx={{
                            mr: 2,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        ITEM
                    </Typography>
                    {props.tripId === 'undefined'? <Paper>Select a trip to view that trips items</Paper>:<AlignItemsList data={gearListData} name="gear" parentCallback={handleCallback}></AlignItemsList>}
                    <div>
                        <IconButton onClick={handleOpen} aria-label="delete">
                            < AddCircleOutlineIcon/>
                        </IconButton>
                        <IconButton aria-label="delete">
                            < DeleteForeverIcon onClick={handleDelete}/>
                        </IconButton>
                    </div>
                </Paper>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h2>Create an Item</h2>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing={2} direction="row" sx={{marginBottom: 4}}>
                            <TextField
                                type="text"
                                variant='outlined'
                                color='secondary'
                                label="Name"
                                onChange={e => setName(e.target.value)}
                                value={name}
                                fullWidth
                                required
                            />
                            <TextField
                                type="text"
                                variant='outlined'
                                color='secondary'
                                label="Description"
                                onChange={e => setDescription(e.target.value)}
                                value={description}
                                fullWidth
                            />
                            <TextField
                                type="text"
                                variant='outlined'
                                color='secondary'
                                label="Quantity"
                                onChange={e => setQuantity(e.target.value)}
                                value={quantity}
                                fullWidth
                            />
                        </Stack>
                        <Button variant="outlined" color="secondary" type="submit">Submit</Button>
                    </form>
                </Box>
            </Modal>
        </Container>

    );

}