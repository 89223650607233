import {addDoc, updateDoc, arrayUnion, doc, deleteDoc, collection, getFirestore} from "firebase/firestore";
import React, {useEffect, useState} from 'react';
import * as FirestoreService from './Services/firestore';
import AlignItemsList from "./ListComponent/list";
import {Box, Button, Container, IconButton, Modal, Paper, Stack, TextField} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ShareIcon from '@mui/icons-material/Share';
import Typography from "@mui/material/Typography";
import Snackbar from '@mui/material/Snackbar';
import NoTripListPage from "./NoTripListPage/noTripListPage";


export default function TripList({data, parentCallback}) {
    const db = getFirestore()
    const [tripList, setTripList] = useState([]);
    const [error, setError] = useState();
    const [openAddTrip, setOpenAddTrip] = useState(false);
    const [openShareTrip, setShareTrip] = useState(false);
    const [name, setName] = useState('');
    const [users, setUsers] = useState([]); //ALL USERS FOR SHARE LIST
    const [description, setDescription] = useState('');
    const [startDate, setStartDate] = useState('');
    const [checkedTrip, setCheckedTripState] = useState([])
    const [checkedUser, setCheckedUserState] = useState([])
    const [openSnackBar, setOpenSnackBar] = useState(false)


    const handleOpen = () => setOpenAddTrip(true);
    const handleClose = () => setOpenAddTrip(false);

    const handleOpenShare = () => {
        const unsubscribe1 = FirestoreService.getUsers(
            (querySnapshot) => {
                const newUsers =
                    querySnapshot.docs.map((doc) =>
                        (
                            {
                                id:doc.data().uid,
                                name: doc.data().name,
                                items:{
                                    email: doc.data().email,
                                    authProvider: doc.data().authProvider
                                }
                            }
                        )
                    );
                console.log(newUsers)
                setUsers(newUsers)
            },
            (error => setError('user-list-get-fail'))
        );
        setShareTrip(true);
        console.log(error);
        return () => {unsubscribe1();}
    }
    const handleCloseShare = () => setShareTrip(false);

    const style = {
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };



    useEffect(() => {
        if(data != null){
            const unsubscribe1 = FirestoreService.streamTripListItemsForUser(data.uid,
                (querySnapshot) => {
                    const updatedTripList =
                        querySnapshot.docs.map((doc) =>
                            (
                                {
                                    id:doc.id,
                                    name: doc.data().name,
                                    items:{
                                        description: doc.data().description,
                                        date: doc.data().start_date?.toDate().toDateString()
                                    }
                                }
                            )
                        );
                    console.log(updatedTripList)
                    // if(gearList != updatedGearList){
                    setTripList(updatedTripList);
                    // }
                },
                (error => setError('trip-list-item-get-fail'))
            );

            // const unsubscribe2 = FirestoreService.streamAppState(
            //     (querySnapshot) => {
            //         console.log(querySnapshot.docs);
            //         setAppState(querySnapshot.docs);
            //     }
            // );
            // return () => {unsubscribe1(); unsubscribe2();}
            return () => {unsubscribe1();}
        }
    }, [data]); //Empty array for dependencies forces single call

    // Callback function to handle data received from the
    //child component
    function handleCallback (childData) {
        // Update the name in the component's state
        if(childData.length>1)
            childData.shift();
        setCheckedTripState(childData);
        parentCallback(childData[0]);
    }

    function handleUserListCallback(childData){
        setCheckedUserState(childData);
    }

    async function handleDelete  () {
        // Update the name in the component's state
        console.log(tripList);
        tripList.forEach(async (item, index, object) =>{
            if (checkedTrip.includes(item.id)) {
                await deleteDoc(doc(db, "Trip", item.id));
            }

        });
    }

    async function handleSubmit(e) {
        e.preventDefault();
        console.log(e.target[0].value)

        const docRef = await addDoc(collection(db, "Trip"), {
            name: e.target[0].value,
            description: e.target[2].value,
            users: [data.uid]
        });

        console.log("Document written with ID: ", docRef.id);

        setOpenAddTrip(false);

    }

    async function handleUserSubmit(e) {
        //write to db
        e.preventDefault();
        console.log("Sharing trip with: " +  checkedUser);
        await updateDoc(doc(db, "Trip", checkedTrip[0]),
        {
            users: arrayUnion(...checkedUser)
        }).then( setOpenSnackBar(true));
        setShareTrip(false);
    }

    function handleSnackBarClose(e){
        setOpenSnackBar(false);
    }

    return (
        <Container maxWidth={"lg"}>
            <Snackbar
                open={openSnackBar}
                autoHideDuration={4000}
                onClose={handleSnackBarClose}
                message="Trip Shared"
                // action={action}
            />
            <Box display="flex"
                 justifyContent="center" alignItems="center">
                    <Paper sx={{background:"#FFCB2B", width:'100%'}}>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            sx={{
                                mr: 2,
                                // display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            TRIP
                        </Typography>
                        { tripList.length > 0 ? <AlignItemsList data={tripList} name="trip" parentCallback={handleCallback}></AlignItemsList> : <NoTripListPage/>}
                        <div>
                            <IconButton onClick={handleOpen} aria-label="add">
                                < AddCircleOutlineIcon/>
                            </IconButton>
                            <IconButton aria-label="delete">
                                < DeleteForeverIcon onClick={handleDelete}/>
                            </IconButton>
                            <IconButton aria-label="share">
                                <ShareIcon onClick={handleOpenShare}/>
                            </IconButton>
                        </div>
                    </Paper>
            </Box>
            <Modal
                open={openAddTrip}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h2>Create A New Trip</h2>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing={2} direction="row" sx={{marginBottom: 4}}>
                            <TextField
                                type="text"
                                variant='outlined'
                                color='secondary'
                                label="Name"
                                onChange={e => setName(e.target.value)}
                                value={name}
                                fullWidth
                                required
                            />
                            <TextField
                                type="text"
                                variant='outlined'
                                color='secondary'
                                label="Description"
                                onChange={e => setDescription(e.target.value)}
                                value={description}
                                fullWidth
                            />
                            <TextField
                                type="date"
                                variant='outlined'
                                color='secondary'
                                label="Start Date"
                                InputLabelProps={{ shrink: true }}
                                onChange={e => setStartDate(e.target.value)}
                                value={startDate || ''}
                                fullWidth
                            />
                        </Stack>
                        <Button variant="outlined" color="secondary" type="submit">Submit</Button>
                    </form>
                </Box>
            </Modal>
            <Modal
                open={openShareTrip}
                onClose={handleCloseShare}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                        <Paper sx={{background:"#FFCB2B", maxHeight:'90%', margin:'15px', overflow:"scroll"}}>
                            <div align={"center"} >
                                <Typography
                                    align={"center"}
                                    variant="h6"
                                    noWrap
                                    component="a"
                                    sx={{
                                        mr: 2,
                                        fontFamily: 'monospace',
                                        fontWeight: 700,
                                        letterSpacing: '.3rem',
                                        color: 'inherit',
                                        textDecoration: 'none',
                                    }}
                                >
                                    Share
                                </Typography>
                            </div>
                                <form onSubmit={handleUserSubmit} >
                                    <AlignItemsList data={users} name="user" parentCallback={handleUserListCallback}></AlignItemsList>
                                    <div align={"center"} >
                                        <Button variant="contained" color="primary" type="submit" sx={{alignItems: 'center'}}>Submit</Button>
                                    </div>
                                </form>


                        </Paper>

                {/*<Container>*/}
                {/*    <Box display="flex"*/}
                {/*         justifyContent="center" alignItems="center">*/}
                {/*        <Paper sx={{background:"#FFCB2B"}}>*/}
                {/*            <Typography*/}
                {/*                align={"center"}*/}
                {/*                variant="h6"*/}
                {/*                noWrap*/}
                {/*                component="a"*/}
                {/*                href="/"*/}
                {/*                sx={{*/}

                {/*                    mr: 2,*/}
                {/*                    fontFamily: 'monospace',*/}
                {/*                    fontWeight: 700,*/}
                {/*                    letterSpacing: '.3rem',*/}
                {/*                    color: 'inherit',*/}
                {/*                    textDecoration: 'none',*/}
                {/*                }}*/}
                {/*            >*/}
                {/*                Share*/}
                {/*            </Typography>*/}
                {/*                <form onSubmit={handleUserSubmit}>*/}
                {/*                        <AlignItemsList data={users} name="user" parentCallback={handleUserListCallback}></AlignItemsList>*/}
                {/*                    <Button variant="contained" color="primary" type="submit" sx={{alignItems: 'center'}}>Submit</Button>*/}
                {/*                </form>*/}
                {/*        </Paper>*/}
                {/*    </Box>*/}
                {/*</Container>*/}
            </Modal>
        </Container>

    );
}