import {Paper} from "@mui/material";
import * as React from 'react';
import {MuiMarkdown} from "mui-markdown";

export default function DevBlogPage() {
    return (
        <Paper sx={{justifyContent: 'flex-start', textAlign: 'left'}}>
            <MuiMarkdown>
                #### Development Blog
            </MuiMarkdown>
            <MuiMarkdown>
                ###### _Future Features_
            </MuiMarkdown>
            <ul>
                <li>Ability to search friends to share with</li>
                <li>Checkbox in items list</li>
                <li>Notifications for users when a list is shared with them</li>
                <li>Option to be emailed when a list is shared with a user</li>
                <li>Option for Desktop/Phone notification when a list is shared with a user</li>
            </ul>
            <MuiMarkdown>
                ###### _Known Bugs_
            </MuiMarkdown>
            <ul>
                <li>When in a small window the menu doesn't disappear after a selection</li>
                <li>White lines appear in the bottom of the page for long lists</li>
            </ul>
        </Paper>

    );
}