import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./LoginComponent/login";
import Register from "./RegisterComponent/register";
import Reset from "./ResetComponent/reset";
import Dashboard from "./DashboardComponent/dashboard";
import Profile from "./ProfileComponent/profile";
import ResponsiveAppBar from "./AppBarComponent/appbar";
import {Container} from "@mui/material";
import Grid from "@mui/material/Grid";
import IdeasPage from "./IdeasPage/ideasPage";
import DevBlogPage from "./DevBlogPage/devBlogPage";
function App() {

  return (
    <div className="App">

        <Router>
            <Grid container={true} alignItems={"center"} display={"flex"} flexDirection={"Column"} >
                <Grid item={true} width={1}>
                    <ResponsiveAppBar/>
                </Grid>
                <Grid item={true} width={1} sx={{height:'calc(100vh - 69px)'}}>
                    <Container maxWidth={false} sx={{height: '100%', alignItems:"stretch"}}>
                        <Routes>
                            <Route exact path="/" element={<Login/>} />
                            <Route exact path="/register" element={<Register />} />
                            <Route exact path="/reset" element={<Reset />} />
                            <Route exact path="/dashboard" element={<Dashboard />} />
                            <Route exact path="/profile" element={<Profile/>} />
                            <Route exact path="/ideas" element={<IdeasPage/>} />
                            <Route exact path="/devblog" element={<DevBlogPage/>} />

                        </Routes>
                    </Container>
                </Grid>

            </Grid>


        </Router>
      {/*<header className="App-header">*/}
      {/*    /!*<GearList/>*!/*/}
      {/*</header>*/}
    </div>
  );
}

export default App;
