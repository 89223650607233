import * as React from 'react';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import {ListItem, ListItemButton} from "@mui/material";
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import NoItemsInListPage from "../NoItemsInListPage/noItemsInListPage";

export default function AlignItemsList({data, parentCallback}) {

    const [checked, setChecked] = React.useState([]);

    const handleToggle = (row) => () => {
        const currentIndex = checked.indexOf(row.id);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(row.id);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
        parentCallback(newChecked);
    };

    function createSecondary (row){
        let secondaryString = "";
        Object.entries(row).map(([key, value]) => {
            return secondaryString +=" — " + key + ": " + value
        })
        return <React.Fragment>
            <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.secondary"
                mx="10px"
            >
                {secondaryString}
            </Typography>
        </React.Fragment>

    }

    return (
        <List>
                {data.length===0?<NoItemsInListPage/>:null}
                {data.map((row, i) =>(
                    <ListItem sx={{ background:"#FFA611", border: '2px solid #FFCB2B' }}>
                    <ListItemButton id={row.id}
                                    onClick={handleToggle(row)}
                        // onClick={(event) => handleListItemClick(event, row.id, row.type )}>
                    >
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={checked.indexOf(row.id) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': row.id }}
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{display: 'inline'}}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        {row? row.name : null}
                                    </Typography>
                                </React.Fragment>
                            }
                            secondary={
                                row.items? createSecondary(row.items):null
                            }
                        />
                        <ListItemAvatar>
                            <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                        </ListItemAvatar>
                    </ListItemButton>
                    </ListItem>
                ))}
        </List>

    );
}