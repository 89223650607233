import {Paper} from "@mui/material";
import * as React from 'react'
import Typography from "@mui/material/Typography";

export default function NoTripListPage() {
    return (
        <Paper>
            <Typography
                variant="h6"
                component="a"
                sx={{
                    mr: 2,
                    // display: { xs: 'none', md: 'flex' },
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                }}
            >
            There are no trips in this list.
            Once you've created a Trip you can add items to it and share it with others.
            Shared Trips allow users to view and make changes to trips in realtime.
            </Typography>
        </Paper>

    );
}