import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from 'react';

export default function NoItemsInListPage() {
    return (
        <Paper>
            <Typography
                variant="h6"
                component="a"
                sx={{
                    mr: 2,
                    // display: { xs: 'none', md: 'flex' },
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                }}
            >
                There are no items in this list.
            </Typography>
        </Paper>

    );
}