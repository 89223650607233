import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "./dashboard.css";
import { auth } from "../Services/firestore";
import GearList from "../GearList";
import TripList from "../TripList";
import {Box} from "@mui/material";
import Grid from '@mui/material/Grid';
import NoSelectedTripListPage from "../NoSelectedTripPage/noSelectedTripPage";

function Dashboard() {
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [selectedTrip, setSelectedTrip] = useState(null);


    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
    }, [user, loading, navigate]);

    function handleCallback(tripId){
        setSelectedTrip(tripId)
    }

    return (
        <Box sx={{display: 'flex', justifyItems:'center',
            flexDirection: 'row', height: '100%' }}>
            <Grid container={true} sx={{display: 'flex', alignItems:'stretch', justifyItems:'center',
                flexDirection: 'row', flexGrow:'1', }}>
                <Grid item={true} sx={{
                    display: 'flex',
                    flexGrow:'1',
                    m: 1,
                    p: 1}}>
                    {user? <TripList data={user}parentCallback={handleCallback}/>: null}
                </Grid>
                <Grid item={true} sx={{
                    display: 'flex',
                    flexGrow:'1',
                    m: 1,
                    p: 1}} >
                    {selectedTrip? <GearList tripId={selectedTrip}/> : <NoSelectedTripListPage/>}
                </Grid>
            </Grid>
        </Box>

    );
}
export default Dashboard;